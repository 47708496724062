header{
  padding: 0px !important;
}

.logo-container{
  font-weight: bold;
  float: left;
  font-size: 2em;
  width: 100%;
  color: #2196f3;
  text-align: center;
  height: 64px;
}

.header-container{
  padding: 0px 10px;
  height: 100%;
  background-color: #2196f3;
  .nav-username{
    font-weight: bold;
    color: white;
    :hover{
      color: #efefef;
    }
    .icon-user {
      font-size: 1.5em;
      padding: 2px;
      margin-right: 10px;
      color: #2196f3;
      background-color: white;
      border-radius: 50%;
    }
  }
}
